main {
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #181717;
  border-radius: 10px;
}

main::-webkit-scrollbar-thumb {
  background: #1e1e24;
  border-radius: 10px;
}

.input-container {
  padding: 10px;
}

form {
  position: relative;
  height: 48px;
  width: 100%;
  max-width: 728px;
  background-color: rgb(58, 58, 58);
  display: flex;
  justify-content: center;
  margin: auto;
  font-size: 1.5rem;
  border-radius: 50px;
}

input {
  width: 100%;
  font-size: 1.2rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 20px;
  border-radius: 50px;
}

form button {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 12px;
  top: 7px;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

form button img {
  transform: scale(0.7);
}
