.message {
  display: flex;
  align-items: center;
  position: relative;
}

.messageText {
  max-width: 500px;
  margin-bottom: 8px;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent .messageText {
  color: white;
  background: #03cb85;
  align-self: flex-end;
}
.received p {
  background: #ffffff;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}
