body {
  background-color: #282c34;
  overflow-y: hidden;
}

.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
  background-color: #50e3c2;
  background-image: radial-gradient(at 47% 33%, hsl(162, 77%, 40%) 0, transparent 59%),
    radial-gradient(at 82% 65%, hsl(198, 100%, 50%) 0, transparent 55%);
}

.App header {
  background-color: #1b1b1b;
  color: white;
  width: 100%;
  max-width: 728px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(256, 256, 256, 0.5);
}

.App-header .logo {
  width: 200px;
  height: auto;
  border-radius: 0;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 89vh;
  background-color: #1b1b1b;
}

button {
  border: none;
  color: white;
  padding: 2% 5%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
  border-radius: 8px;
}

.signIn-btn {
  max-width: 250px;
  width: 100%;
  margin: auto;
  background-color: #4285f4;
}

.signOut-btn {
  padding: 4px;
  background: transparent;
}
.signOut-btn img {
  width: 60px;
  height: auto;
  margin: 0;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}
